/**
 * Internal CMS types that are agnostic to CMS provider.
 *
 * @note most of the types reflect the GQL types from ts-public-routes
 * but we want to get away from going through ts-public-routes and use landing SSR instead.
 * @see `CmsPageContentGql` in ts-public-routes
 *
 * @todo this should be server-only
 */

import type { ZodType } from 'zod';
import { z } from 'zod';
import type { Document } from '@contentful/rich-text-types';
import type { Locale, Segment } from '@fxtr/i18n';
import type { PageRouteRedirect } from '@fxtr/model-page-router/PageRoute';
import type { SchemaType } from '$util/schema';
import type { ContentComponentTranslatedEntities } from '$cms/ContentComponent';
import { JsonSchema } from '$util/schema';

export interface CmsPageRoute {
  readonly id: string;
  readonly path: string;
  /**
   * Collection items data from a page template with collection placeholders.
   */
  readonly collectionItems?: CmsPageCollectionItems;
  readonly redirect?: PageRouteRedirect;
}

export const ContentfulDocumentSchema = JsonSchema as unknown as ZodType<Document>;

export const CmsAreaItemSchema = z.object({
  id: z.string(),
  name: z.string(),
  slug: z.string(),
  popularity: z.number().optional(),
  centerLocation: z.tuple([z.number(), z.number()]),
  parent: z.string().optional().nullable(),
  shortDescription: ContentfulDocumentSchema.optional(),
  description: ContentfulDocumentSchema.optional(),
});

export type CmsAreaItem = SchemaType<typeof CmsAreaItemSchema>;

export const CmsProductItemSchema = z.object({
  id: z.string(),
  name: z.string(),
  /**
   * Can be a single SKU, a ProductGroup SKU or multiple ones separated by semicolon.
   */
  sku: z.string(),
  slug: z.string(),
  image: z.string().optional(),
  popularity: z.number().optional(),
  shortDescription: ContentfulDocumentSchema.optional(),
  description: ContentfulDocumentSchema.optional(),
  shortSymptoms: ContentfulDocumentSchema.optional(),
  symptoms: ContentfulDocumentSchema.optional(),
  shortFrequency: ContentfulDocumentSchema.optional(),
  frequency: ContentfulDocumentSchema.optional(),
  shortItemsReplaced: ContentfulDocumentSchema.optional(),
  itemsReplaced: ContentfulDocumentSchema.optional(),
});

export type CmsProductItem = SchemaType<typeof CmsProductItemSchema>;

export const CmsCarMakeItemSchema = z.object({
  id: z.string(),
  name: z.string(),
  logo: z.string(),
  image: z.string(),
  slug: z.string(),
  popularity: z.number().nonnegative().optional(),
  shortDescription: ContentfulDocumentSchema.optional(),
  description: ContentfulDocumentSchema.optional(),
  shortKnownIssues: ContentfulDocumentSchema.optional(),
  knownIssues: ContentfulDocumentSchema.optional(),
});

export type CmsCarMakeItem = SchemaType<typeof CmsCarMakeItemSchema>;

export const CmsCarModelItemSchema = z.object({
  id: z.string(),
  name: z.string(),
  slug: z.string(),
  make: CmsCarMakeItemSchema.pick({ id: true, name: true, slug: true }),
  popularity: z.number().optional(),
});

export type CmsCarModelItem = SchemaType<typeof CmsCarModelItemSchema>;

export const CmsSymptomItemSchema = z.object({
  id: z.string(),
  name: z.string(),
  category: z.string(),
  slug: z.string(),
  title: z.string(),
  subTitle: z.string(),
  image: z.string(),
  popularity: z.number().optional(),
  shortDescription: ContentfulDocumentSchema.optional(),
  description: ContentfulDocumentSchema.optional(),
});

export type CmsSymptomItem = SchemaType<typeof CmsSymptomItemSchema>;

export type CmsCollectionItems =
  | CmsAreaItem
  | CmsCarMakeItem
  | CmsCarModelItem
  | CmsProductItem
  | CmsSymptomItem;

export const CmsPageCollectionItemsSchema = z.object({
  area: CmsAreaItemSchema.optional(),
  product: CmsProductItemSchema.optional(),
  carMake: CmsCarMakeItemSchema.optional(),
  carModel: CmsCarModelItemSchema.optional(),
  symptom: CmsSymptomItemSchema.optional(),
});

/**
 * CMS Collection Items mapped by collection type.
 */
export type CmsPageCollectionItems = SchemaType<typeof CmsPageCollectionItemsSchema>;

export interface CmsPageSeo {
  readonly noindex: boolean;
  readonly nofollow: boolean;
  readonly title?: string;
  readonly metaDescription?: string;
  readonly metaKeywords?: string[];
}

/**
 * Data for a CMS dynamic page
 */
export interface CmsPageContent {
  readonly id: string;
  readonly segment: Segment;
  readonly pathPattern: string;
  readonly provider: string;
  readonly revision: number;
  /**
   * Each page will have only one route in the array.
   * @note This is an array just for Apollo Cache to be able to cache multiple routes under the same template.
   */
  readonly pageRoutes: CmsPageRoute[];
  readonly seo?: CmsPageSeo;
  readonly components?: CmsContentComponent[];
}

/**
 * Data for a CMS dynamic component
 */
export interface CmsContentComponent<T = Record<string, unknown>> {
  readonly id: string;
  readonly locale: Locale;
  readonly uiComponent: string;
  readonly translatedEntities?: CmsTranslatedEntity[];
  readonly configuration?: T;
}

/**
 * @todo fix existing keys names and enforce convention at validation
 *
 * Enforce key convention: SectionName.parameter.variation
 * `PascalCase.camelCase.camelCase`
 */
export const CmsTranslatedEntityKeySchema = z.string();
// export const CmsTranslatedEntityKeySchema = z
//   .string({
//     invalid_type_error: 'A translated entity key has to follow convention `SectionName.parameter.variation`',
//   })
//   .regex(/(^([A-Z][a-z]+)+)\.([a-z]+((\d)|[a-zA-Z0-9]+))\.([a-z]+((\d)|[a-zA-Z0-9]+))/g);

export const CmsShortTextSchema = z.object(
  {
    key: CmsTranslatedEntityKeySchema,
    value: z.string(),
  },
  {
    required_error: 'ShortText is required',
    invalid_type_error: 'Expected ShortText content type',
  }
);

export type CmsShortText = SchemaType<typeof CmsShortTextSchema>;

export const CmsLongTextSchema = z.object(
  {
    key: CmsTranslatedEntityKeySchema,
    value: z.string(),
  },
  {
    required_error: 'LongText is required',
    invalid_type_error: 'Expected LongText content type',
  }
);

export type CmsLongText = SchemaType<typeof CmsLongTextSchema>;

export const CmsContentfulImageSchema = z.object({
  url: z.string(),
  alt: z.string(),
});

export const CmsImageSchema = z.object(
  {
    key: CmsTranslatedEntityKeySchema,
    url: z.string(),
    width: z.number().nonnegative(),
    height: z.number().nonnegative(),
    alt: z.string(),
    title: z.string().optional(),
  },
  {
    required_error: 'Image is required',
    invalid_type_error: 'Expected Image content type',
  }
);

export type CmsImage = SchemaType<typeof CmsImageSchema>;

export const CmsSingleImageSchema = z.object({
  url: z.string(),
  alt: z.string(),
});

export const CmsRichTextSchema = z.object(
  {
    key: CmsTranslatedEntityKeySchema,
    content: JsonSchema as unknown as z.ZodType<Document>,
  },
  {
    required_error: 'RichText is required',
    invalid_type_error: 'Expected RichText content type',
  }
);

export type CmsRichText = SchemaType<typeof CmsRichTextSchema>;

export const CmsLinkSchema = z.object(
  {
    key: CmsTranslatedEntityKeySchema,
    text: z.string(),
    type: z.number().optional(),
    url: z.string().refine((val) => {
      return (
        ['http://', 'https://', '/', '#'].some((protocol) => val.startsWith(protocol)),
        'Please provide a valid URL'
      );
    }),
  },
  {
    required_error: 'Link is required',
    invalid_type_error: 'Expected Link content type',
  }
);

export type CmsLink = SchemaType<typeof CmsLinkSchema>;

export const CmsLinkSchemaNullable = CmsLinkSchema.extend({
  key: z.string().nullable(),
  text: z.string().nullable(),
  url: z.string().nullable(),
});

export type CmsLinkNullable = SchemaType<typeof CmsLinkSchemaNullable>;

export const CmsNumberSchema = z.object(
  {
    key: CmsTranslatedEntityKeySchema,
    number: z.number(),
  },
  {
    required_error: 'Number is required',
    invalid_type_error: 'Expected Number content type',
  }
);

export type CmsNumber = SchemaType<typeof CmsNumberSchema>;

export type CmsTranslatedEntity = CmsShortText | CmsLongText | CmsImage | CmsRichText | CmsLink | CmsNumber;

export type PricingTableItem = SchemaType<typeof PricingTableItemSchema>;

export const PricingTableItemSchema = z.object({
  makeName: z.string(),
  modelName: z.string(),
  sku: z.string(),
  skuName: z.string(),
  marketPrice: z.number().positive(),
  lowestPrice: z.number().positive(),
  highestPrice: z.number().positive(),
  createdAt: z.string(),
});

export const isCmsShortText = (entity?: CmsShortText | CmsLink): entity is CmsShortText =>
  !!entity && 'value' in entity;

export const isCmsLink = (entity?: CmsShortText | CmsLink): entity is CmsLink => !!entity && 'url' in entity;

/**
 * Allowed collection path placeholders.
 */
export const collectionPathPlaceholders = ['area', 'product', 'car-make', 'car-model', 'symptom'] as const;
export type CollectionPathPlaceholder = (typeof collectionPathPlaceholders)[number];

export interface CmsFilter {
  readonly key: string;
  readonly operator: string;
  readonly value: string | number;
}

export interface PageConfigurationComponent {
  readonly locale: Locale;
  readonly __typename: string;
  readonly id: string;
  readonly uiComponent: string;
  readonly translatedEntities: ContentComponentTranslatedEntities;
}

export interface CmsPageData {
  readonly seo: CmsPageSeo;
  readonly components: CmsContentComponent[];
  readonly configuration: PageConfigurationComponent;
}
